var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading)?_c('MainLoader'):_c('div',{staticClass:"study"},[_c('CoorserCourse'),(_vm.showExamRegistration)?_c('div',{staticClass:"study__exam-wrapper"},[(
        !_vm.user.latest_exam ||
        _vm.user.latest_exam.status === 'not_passed' ||
        _vm.user.latest_exam.status === 'missed' ||
        _vm.user.latest_exam.status === 'canceled'
      )?_c('div',{staticClass:"study__exam"},[_c('p',[_vm._v(" Изучите все материалы и записывайтесь на экзамен, чтобы получить возможность заключать договора с ТСП ")]),(_vm.user.test && _vm.user.test.status === 'passed')?_c('exam-registration',{attrs:{"invalid-date":_vm.invalidDate},on:{"storeExam":_vm.storeExamRequest}}):_vm._e(),(_vm.test_available || (_vm.user.test && _vm.user.test.status !== 'passed'))?_c('test-registration',{attrs:{"test-status":_vm.test_status}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"study__exam"},[(
          _vm.user.latest_exam &&
          !(
            _vm.user.latest_exam.status === 'not_passed' ||
            _vm.user.latest_exam.status === 'missed' ||
            _vm.user.latest_exam.status === 'canceled'
          )
        )?_c('exam-info',{attrs:{"exam":_vm.user.latest_exam},on:{"destroyExam":_vm.destroyExamRequest}}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"study__tutorials"},_vm._l((_vm.tutorials),function(tutorial){return _c('study-preview',{key:tutorial.id,attrs:{"tutorial":tutorial}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }