<script>
import { mapActions, mapState } from "vuex";
import MainButton from "@/components/helpers/MainButton.vue";
import MainCheckbox from "@/components/helpers/MainCheckbox.vue";
import CourseProgress from "@/components/coorser/CourseProgress.vue";

export default {
  name: "CoorserCourse",
  components: { CourseProgress, MainCheckbox, MainButton },

  data() {
    return {
      agreement: false,
      course: null,
      premium_d: [485, 29],
      vip_gold_d: [486, 25],
      vip_d: [486, 25],
    };
  },

  watch: {
    courses: {
      handler() {
        this.course = this.selectCourse(this.tariffCode);
      },
      deep: true,
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      courses: (state) => state.coorser.courses,
      tariffCode: (state) => state.auth.user.tariff.code,
    }),

    percent() {
      if (!this.course.stats) {
        return 0;
      }

      return Math.trunc(
        (this.course.stats.completed_units * 100) /
          (this.course.stats.total_units || 1)
      );
    },
  },

  methods: {
    ...mapActions({
      loadCourses: "coorser/loadCourses",
      attachToCourse: "coorser/attachToCourse",
    }),

    attach() {
      if (!this.agreement) return;

      this.attachToCourse({ course_id: this.course.id }).then(() => {
        this.loadCourses().then(() => {
          this.toCoorser();
          this.course = this.selectCourse(this.tariffCode);
        });
      });
    },

    toCoorser() {
      window.open(
        `${process.env.VUE_APP_COORSEER_URL}panel/courses/${this.course.id}?phone=${this.user.phone}`
      );
    },

    selectCourse(tariff) {
      return (
        this.courses.find((course) => this[tariff].includes(course.id)) ?? null
      );
    },
  },

  created() {
    this.course = this.selectCourse(this.tariffCode);
  },
};
</script>

<template>
  <div v-if="course" class="coorser-course">
    <section class="coorser-course__content">
      <p
        v-if="course.stats && course.stats.is_attached"
        class="coorser-course__reg-mark"
      >
        Вы записаны на курс
      </p>

      <img
        class=""
        src="../../assets/images/coorser_image.png"
        alt="coorser-course"
      />

      <section class="coorser-course__info">
        <h2>{{ course.name }}</h2>

        <p>{{ course.description }}</p>
      </section>

      <template v-if="!course.stats || !course.stats.is_attached">
        <MainButton
          title="Записаться на курс"
          color="gold"
          :disabled="!agreement"
          padding="13px 24px"
          @click="attach"
        />

        <MainCheckbox
          v-model="agreement"
          title="Я даю согласие на передачу моих личных персональных данных в АО «ИнФинТех» для регистрации на обучающей платформе «Курсир»"
        />
      </template>

      <section v-else class="courser-course__about-course about-course">
        <CourseProgress class="about-course__progress" :percent="percent" />

        <MainButton
          padding="13px 24px"
          class=""
          color="gold"
          title="Перейти к курсу"
          @click="toCoorser"
        />

        <MainButton
          padding="13px 24px"
          color="outlined-grey"
          title="Подробнее"
          @click="$router.push({ name: 'Course', query: { id: course.id } })"
        />
      </section>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.coorser-course {
  border-radius: 8px;
  background: $light-primary;
  margin-bottom: 32px;
  max-width: 420px;
  position: relative;

  &__reg-mark {
    @include text-2;
    color: $light-primary !important;
    padding: 4px 8px;
    border-radius: 8px;
    background: $dark-primary;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
  }

  img {
    width: 100%;
    padding: 0 10px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  h2 {
    @include title-3;
    color: $dark-primary;
  }

  p {
    @include body-1;
    color: $dark-second;
  }

  .main-button {
    width: 100%;
  }

  :deep .main-checkbox input + label span {
    max-width: 100%;
  }
}

.about-course {
  display: grid;
  grid-template-rows: auto;

  .main-button {
    width: 100% !important;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 900px) {
  .coorser-course {
    margin-bottom: 64px;
  }
}

@media (min-width: 1024px) {
  .coorser-course {
    max-width: 598px;
    background-image: url("../../assets/images/coorser_background.png");
    background-position: right bottom;
    background-size: 180px 152px;
    background-repeat: no-repeat;

    &__reg-mark {
      top: 16px;
      right: 16px;
    }

    &__content {
      padding: 32px;
      max-width: calc(100% - 180px);
    }

    img {
      display: none;
    }

    .main-button {
      width: fit-content !important;
    }
  }

  .about-course {
    grid-template-rows: auto auto;
    grid-template-columns: fit-content(186px) auto;
    grid-auto-flow: row;
    column-gap: 8px;

    &__progress {
      grid-column: -1/1;
      grid-row: 1/1;
    }

    .main-button {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1440px) {
  .coorser-course {
    max-width: 820px;
    background-size: 280px 236px;
    background-repeat: no-repeat;

    &__content {
      padding: 32px;
      max-width: calc(100% - 280px);
    }
  }
}
</style>
