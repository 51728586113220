import { render, staticRenderFns } from "./TestRegistration.vue?vue&type=template&id=1df14cb1&scoped=true"
import script from "./TestRegistration.vue?vue&type=script&lang=js"
export * from "./TestRegistration.vue?vue&type=script&lang=js"
import style0 from "./TestRegistration.vue?vue&type=style&index=0&id=1df14cb1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1df14cb1",
  null
  
)

export default component.exports