<script>
export default {
  props: {
    value: Boolean,

    modelValue: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: "checkbox",
    },

    title: {
      type: String,
      default: "",
    },

    titlePosition: {
      type: String,
      default: "right",
    },

    href: {
      type: String,
      default: "",
    },

    linkText: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<template>
  <div
    class="main-checkbox"
    :class="[
      { 'main-checkbox_title-left': titlePosition === 'left' },
      { 'main-checkbox_title-right': titlePosition === 'right' },
      { 'main-checkbox_disabled': disabled },
    ]"
  >
    <input
      :id="id"
      v-model="model"
      type="checkbox"
      :value="modelValue"
      :disabled="disabled"
    />
    <label :for="id">
      <span>
        {{ title }}
        <a v-if="linkText" :href="href" download>{{ linkText }}</a>
      </span>
    </label>
  </div>
</template>

<style scoped lang="scss">
.main-checkbox {
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    margin: 0;
    width: 16px;
    height: 16px;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      display: flex;
      @include text-2;
      color: $dark-primary;

      span {
        max-width: 255px;
      }

      a {
        color: $blue;
        text-decoration: none;
      }
    }

    // Box hover
    &:hover + label:before {
      //background: #f35429;
    }

    // Box focus
    &:focus + label:before {
      //box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
      //background: #f35429;
    }

    // Disabled state label.
    &:disabled + label {
      //color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      //background: #ddd;
    }
  }

  &_disabled {
    opacity: 0.5;
  }

  &_title-right {
    input + label:before {
      content: "";
      margin-right: $space-s;
      display: inline-block;
      min-width: 16px;
      width: 16px;
      height: 16px;
      border: 2px solid $dark-primary;
      border-radius: $space-xxs;
    }

    input:checked + label:after {
      content: "";
      position: absolute;
      left: 3px;
      top: 7px;
      background: $dark-primary;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 $dark-primary, 4px 0 0 $dark-primary,
        4px -2px 0 $dark-primary, 4px -4px 0 $dark-primary,
        4px -6px 0 $dark-primary, 4px -7px 0 $dark-primary;
      transform: rotate(45deg);
    }
  }

  &_title-left {
    input + label:after {
      content: "";
      margin-left: $space-s;
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 2px solid $dark-primary;
      border-radius: $space-xxs;
    }

    input:checked + label:before {
      content: "";
      position: absolute;
      right: 11px;
      top: 7px;
      background: $dark-primary;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 $dark-primary, 4px 0 0 $dark-primary,
        4px -2px 0 $dark-primary, 4px -4px 0 $dark-primary,
        4px -6px 0 $dark-primary, 4px -7px 0 $dark-primary;
      transform: rotate(45deg);
    }
  }
}
</style>
