<template>
  <div class="test__registration">
    <h3>Проверка знаний</h3>
    <p>
      Изучите материалы и пройдите проверочный тест из 20 вопросов. Чтобы
      успешно пройти тестирование и получить доступ к экзамену, нужно допустить
      не более 2 ошибок. Количество попыток неограниченно.
    </p>
    <div
      v-if="user.test && user.test.status === 'not_passed'"
      class="test__registration__status"
    >
      <p>
        Предыдущая попытка <span>{{ lastTryDate }}</span>
      </p>
      <div class="test__registration__result">
        <div class="test__registration__progress">
          <div :style="{ width: `${calculateLineWidth}%` }" />
        </div>
        <p>верно {{ user.test.correct }} из 20</p>
      </div>
    </div>
    <main-button
      :title="
        user.test && user.test.status === 'not_passed'
          ? 'Перепройти тест'
          : 'Пройти тест'
      "
      color="gold"
      padding="14px 60px"
      @click="goTest"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MainButton from "@/components/helpers/MainButton";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

export default {
  name: "TestRegistration",

  components: { MainButton },

  props: {
    testStatus: {
      type: String,
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    lastTryDate() {
      return format(new Date(this.user.test.created_at), "dd MMMM", {
        locale: ru,
      });
    },

    calculateLineWidth() {
      return Math.round((this.user.test.correct / 20) * 100);
    },
  },

  methods: {
    ...mapActions({
      startTest: "tutorial/startTest",
    }),

    goTest() {
      if (!this.testStatus || this.testStatus === "not_passed") {
        this.startTest().then(() => {
          this.$router.push({ name: "StudyTest" });
        });
        return;
      }

      this.$router.push({ name: "StudyTest" });
    },
  },
};
</script>

<style scoped lang="scss">
.test__registration {
  border-radius: 8px;
  background-color: $background-white;
  padding: $space-xl;

  > h3 {
    color: $dark-primary;
    @include body-1-bold;
    margin: 0 0 $space-m;
  }

  > p {
    @include body-1();
    color: $black;
    margin: 0 0 $space-m;
  }
  > button {
    width: auto;
  }
  &__status {
    margin: 0 0 $space-l;
    > p {
      @include text-2();
      color: $dark-primary;
      margin: 0 0 $space-s;
      > span {
        color: $dark-sixth;
      }
    }
  }
  &__result {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: $space-xs;
    > p {
      @include caption-1();
      color: $dark-primary;
    }
  }
  &__progress {
    width: 270px;
    height: 16px;
    border-radius: 8px;
    background-color: $light-fifth;
    position: relative;
    overflow: hidden;
    > div {
      height: 16px;
      border-radius: 8px;
      background-color: $green;
      position: absolute;
    }
  }
}
</style>
