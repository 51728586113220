<template>
  <div class="exam-info">
    <template v-if="exam.status === 'passed'">
      <h3 class="exam-info__title exam-info__title--passed">
        Экзамен сдан! <img src="../../assets/icons/exam-passed.png" alt="" />
      </h3>

      <p class="exam-info__time exam-info__time--passed">
        {{
          new Date(exam.passed_at).toLocaleString("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })
        }}
      </p>
    </template>

    <template v-else>
      <h3 class="exam-info__title">Вы записаны на экзамен</h3>

      <p class="exam-info__time">
        {{
          new Date(exam.passed_at).toLocaleString("ru-RU", {
            timeZone: "Europe/Moscow",
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        }}
        (московское время)
      </p>

      <div class="exam-info__cancel">
        <main-button
          color="dark"
          title="Отменить запись"
          @click.native="$emit('destroyExam')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import MainButton from "@/components/helpers/MainButton";

export default {
  components: {
    MainButton,
  },

  props: {
    exam: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.exam-info {
  background-color: $background-white;
  padding: $space-xl;
  border-radius: $space-s;

  &__title {
    margin-bottom: $space-m;
    @include body-1-bold;
    color: $dark-primary;

    &--passed {
      margin-bottom: $space-s;
      display: flex;
      align-items: center;

      img {
        pointer-events: none;
        margin-left: $space-s;
      }
    }
  }

  &__time {
    margin-bottom: $space-xxl;
    @include body-1;
    color: $dark-primary;

    &--passed {
      @include body-1;
      color: $light-sixth;
      margin-bottom: 0;
    }
  }

  &__cancel {
    max-width: 300px;
  }
}
</style>
