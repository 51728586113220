<script>
import { mapState, mapActions } from "vuex";
import { Datetime } from "vue-datetime";
import { format } from "date-fns";
import MainButton from "@/components/helpers/MainButton";
import MainSelect from "@/components/helpers/MainSelect";

export default {
  name: "ExamRegistration",

  components: {
    MainButton,
    MainSelect,
    datetime: Datetime,
  },

  props: {
    invalidDate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      countries: [
        {
          name: "Россия",
          value: "RU",
        },
        {
          name: "Казахстан",
          value: "KZ",
        },
      ],
      times: [
        {
          name: "10:40",
          value: "10:40",
        },
        {
          name: "11:20",
          value: "11:20",
        },
        {
          name: "14:00",
          value: "14:00",
        },
        {
          name: "14:40",
          value: "14:40",
        },
        {
          name: "15:20",
          value: "15:20",
        },
        {
          name: "16:00",
          value: "16:00",
        },
      ],
      country: {
        name: "Россия",
        value: "RU",
      },
      time: {},
      date: "",
    };
  },

  watch: {
    date() {
      this.time = {};
      const formattedDate = format(new Date(this.date), "yyyy-MM-dd");

      this.checkHoursForBooking({ date: formattedDate }).then(() => {
        if (this.formattedListOfHours.length > 0) {
          this.time = this.formattedListOfHours[0];
        }
      });
    },
  },

  computed: {
    ...mapState({
      hoursForBooking: (state) => state.tutorial.hoursForBooking,
    }),

    formattedDateToISO() {
      let date = new Date();
      date.setDate(date.getDate() + 1);

      return date.toISOString();
    },

    formattedListOfHours() {
      const filteredHoursForBooking = this.hoursForBooking.filter((hour) => {
        return hour.is_booked === false;
      });

      return filteredHoursForBooking.map((hour) => {
        return {
          name: hour.time,
          value: hour.time,
        };
      });
    },
  },

  methods: {
    ...mapActions({
      checkHoursForBooking: "tutorial/checkHoursForBooking",
    }),

    formatDate(date) {
      const year = new Date(date).getFullYear();
      const month =
        new Date(date).getMonth() + 1 < 10
          ? "0" + (new Date(date).getMonth() + 1)
          : new Date(date).getMonth() + 1;
      const day =
        new Date(date).getDate() < 10
          ? "0" + new Date(date).getDate()
          : new Date(date).getDate();

      return year + "-" + month + "-" + day;
    },

    confirmStoreExam() {
      let data = {
        time: this.time.value,
        country: this.country.value,
        date: this.formatDate(this.date),
      };

      this.$emit("storeExam", data);
    },
  },
};
</script>

<template>
  <div class="exam__registration">
    <div class="exam__registration__title">Запись на экзамен</div>

    <div class="exam__registration__selectors">
      <main-select
        class="top"
        :options="countries"
        :selected-option.sync="country"
        style-type="background-gray"
        title="Страна"
      />

      <div
        class="exam__registration__date-wrapper bottom-left"
        :class="{ 'exam__registration__invalid-date': invalidDate }"
      >
        <p>Дата (Только будни)</p>
        <datetime
          v-model="date"
          :input-class="[
            'exam__registration__date',
            { 'exam__registration__date--error': invalidDate },
          ]"
          :phrases="{ ok: 'Ок', cancel: 'Отмена' }"
          format="dd.MM.yyyy"
          :min-datetime="formattedDateToISO"
        />
        <span>Выбранная дата недоступна для записи</span>

        <p
          v-if="formattedListOfHours.length === 0 && hoursForBooking.length > 0"
          class="exam__registration__error"
        >
          В этот день свободных слотов нет
        </p>
      </div>

      <main-select
        v-if="formattedListOfHours.length > 0"
        class="bottom-right"
        :options="formattedListOfHours"
        :selected-option.sync="time"
        style-type="background-gray"
        title="Время (МСК)"
      />
    </div>

    <div class="exam__registration__button">
      <main-button
        title="Записаться на экзамен"
        color="gold"
        :disabled="!(country && Object.keys(time).length !== 0 && date)"
        @click.native="confirmStoreExam"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.exam__registration {
  border-radius: 8px;
  background-color: $background-white;
  padding: $space-xl;

  &__title {
    color: $dark-primary;
    @include body-1-bold;
  }

  &__selectors {
    margin: $space-l 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: $space-m;
    grid-column-gap: $space-l;
    grid-template-areas:
      "top top"
      "bottom-left bottom-right";

    .top {
      grid-area: top;
    }

    .bottom-left {
      grid-area: bottom-left;
    }

    .bottom-right {
      grid-area: bottom-right;
    }

    .main-select-wrapper {
      max-width: 210px;
    }

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__date-wrapper {
    width: 210px;
    position: relative;
    padding-top: 25px;

    @media (max-width: 576px) {
      width: 100%;
    }

    p {
      position: absolute;
      left: 0;
      top: 0;
      @include caption-1;
      color: $dark-primary;
    }

    span {
      display: none;
      @include caption-1;
      color: $red;
      margin-top: $space-s;
      white-space: nowrap;
    }
  }

  &__invalid-date {
    span {
      display: block;
    }
  }

  &__error {
    position: absolute;
    left: calc(100% + $space-m) !important;
    top: $space-xl !important;
    @include caption-1;
    color: $red !important;
    max-width: 140px;
    width: 100%;
    height: fit-content;
  }

  &__button {
    max-width: 300px;
  }
}

@media (max-width: 576px) {
  .exam__registration {
    &__error {
      position: static !important;
      max-width: 100%;
      margin-top: $space-l;
    }
  }
}
</style>
