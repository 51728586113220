<script>
import { mapState, mapActions } from "vuex";
import StudyPreview from "@/components/tutorial/StudyPreview";
import ExamRegistration from "@/components/tutorial/ExamRegistration.vue";
import ExamInfo from "@/components/tutorial/ExamInfo";
import TestRegistration from "@/components/tutorial/TestRegistration.vue";
import CoorserCourse from "@/components/tutorial/CoorserCourse.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";

export default {
  name: "Study",
  components: {
    MainLoader,
    CoorserCourse,
    TestRegistration,
    StudyPreview,
    ExamRegistration,
    ExamInfo,
  },

  data() {
    return {
      params: {
        page: 1,
      },

      test_status: "",
      invalidDate: false,
      test_available: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      tutorials: (state) => state.tutorial.tutorials,
    }),

    showExamRegistration() {
      const userTariff = this.user.tariff?.code;

      return userTariff === "vip_d" || userTariff === "vip_gold_d";
    },
  },

  methods: {
    ...mapActions({
      checkUser: "auth/checkUser",
      loadTutorials: "tutorial/loadTutorials",
      storeExam: "tutorial/storeExam",
      destroyExam: "tutorial/destroyExam",
      checkTest: "tutorial/checkTest",
      loadTest: "tutorial/loadTest",
      loadCourses: "coorser/loadCourses",
    }),

    storeExamRequest(data) {
      this.storeExam(data)
        .then(() => {
          this.checkUser();
        })
        .catch(() => {
          this.invalidDate = true;

          setTimeout(() => {
            this.invalidDate = false;
          }, 10000);
        });
    },

    destroyExamRequest() {
      this.destroyExam(this.user.latest_exam.id).then(() => {
        this.checkUser();
      });
    },

    loadingCourse() {
      if (
        this.user.tariff?.code === "vip_d" ||
        this.user.tariff?.code === "vip_gold_d" ||
        this.user.tariff?.code === "premium_d"
      ) {
        return this.loadCourses();
      }
    },
  },

  created() {
    this.isLoading = true;

    Promise.all([
      this.loadingCourse(),
      this.loadTutorials(),
      this.checkUser(),
    ]).finally(() => {
      if (!this.user.test || this.user.test?.status !== "passed") {
        this.checkTest().then(() => {
          this.test_available = true;
        });
        this.loadTest().then((response) => {
          this.test_status = response.data.data.status;
        });
      }

      this.isLoading = false;
    });
  },
};
</script>

<template>
  <MainLoader v-if="isLoading" />

  <div v-else class="study">
    <CoorserCourse />

    <div v-if="showExamRegistration" class="study__exam-wrapper">
      <div
        v-if="
          !user.latest_exam ||
          user.latest_exam.status === 'not_passed' ||
          user.latest_exam.status === 'missed' ||
          user.latest_exam.status === 'canceled'
        "
        class="study__exam"
      >
        <p>
          Изучите все материалы и записывайтесь на экзамен, чтобы получить
          возможность заключать договора с ТСП
        </p>

        <exam-registration
          v-if="user.test && user.test.status === 'passed'"
          :invalid-date="invalidDate"
          @storeExam="storeExamRequest"
        />

        <test-registration
          v-if="test_available || (user.test && user.test.status !== 'passed')"
          :test-status="test_status"
        />
      </div>

      <div class="study__exam">
        <exam-info
          v-if="
            user.latest_exam &&
            !(
              user.latest_exam.status === 'not_passed' ||
              user.latest_exam.status === 'missed' ||
              user.latest_exam.status === 'canceled'
            )
          "
          :exam="user.latest_exam"
          @destroyExam="destroyExamRequest"
        />
      </div>
    </div>

    <div class="study__tutorials">
      <study-preview
        v-for="tutorial in tutorials"
        :key="tutorial.id"
        :tutorial="tutorial"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.study {
  padding: 0 $space-xxl $space-xxl;
  overflow: hidden;

  @media (max-width: 576px) and (min-width: 426px) {
    padding: 0 $space-xl $space-xxl;
  }

  @media (max-width: 425px) {
    padding: 0 $space-l $space-xl;
  }

  @media (max-width: 375px) {
    padding: 0 $space-m $space-xl;
  }

  &__tutorials {
    display: grid;
    grid-template-columns: repeat(3, 390px);
    grid-gap: $space-xl;
    @media (max-width: 1919px) {
      grid-template-columns: repeat(2, 420px);
    }
    @media (max-width: 1440px) {
      grid-template-columns: repeat(1, minmax(270px, 420px));
    }
  }

  &__exam-wrapper {
    max-width: 600px;
  }

  &__exam {
    margin-bottom: $space-xxxl;

    p {
      @include body-1;
      color: $dark-primary;
      margin-bottom: $space-m;
    }
  }
}
</style>
